import { GTM_CUSTOM_EVENT } from "../util/types";

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

export const triggerCustomEvent = (eventName: GTM_CUSTOM_EVENT) => {
  if (typeof window !== "undefined") {
    window.dataLayer?.push({ event: eventName });
  }
};
